import React, { MouseEvent } from 'react'
import ItemsList from './ItemsList';
import ReactDOM from 'react-dom';
import axios from "axios";
//import App from "./App";
import LogTransaction from "./LogTransaction"
import { isThisTypeNode } from 'typescript';
//import { Recoverable } from 'node:repl';

const APPLICATION_ENV = process.env.REACT_APP_APPLICATION_ENV!;
//console.log(APPLICATION_ENV);

const DISCORD_URL = "https://discord.gg/d5uTJ7r6VM";

const CEMETERY_ADDRESS_TEST = "0xb365C6E5260805a1208937056b617D4068E51587";
const CEMETERY_ADDRESS_LIVE = "0xb365C6E5260805a1208937056b617D4068E51587";
const CEMETERY_ADDRESS = APPLICATION_ENV === 'development' ? CEMETERY_ADDRESS_TEST : CEMETERY_ADDRESS_LIVE;

const ITEM_CONTRACT_TEST = "0x5ddb47ee5a8c53c6a691de7db99a53e6c63e3748";
const ITEM_CONTRACT_LIVE = "0x95ae387d8da3c218054772f682b662ef4fca7b8e";
const ITEM_CONTRACT = APPLICATION_ENV === 'development' ? ITEM_CONTRACT_TEST : ITEM_CONTRACT_LIVE;

class Burn extends React.Component<{}, { visible_select_items: string, selected_tokenId: number[], selected_image_url: string[], selectting: number, show_confirm: boolean, burning: boolean, burn_result: boolean, error: boolean, transfer_txhash: string, mint_txhash: string, new_comer: any }>{
  public listData: any[] = [];
  //public selected_1 :number = 0;
  //public selected_2 :number = 1;
  static selected_1: number = 0;
  static selected_2: number = 1;
  constructor(props: {}) {
    super(props);
    this.state = {
      visible_select_items: 'none',
      selected_tokenId: [0, 0],
      selected_image_url: ["", ""],
      selectting: 0,
      show_confirm: false,
      burning: false,
      burn_result: false,
      error: false,
      transfer_txhash: "",
      mint_txhash: "",
      new_comer: { token_id: 0, image_url: "assets/css/images/spin.svg" }
    };
  }

  async loadData(selected: number) {
//    console.log("load items list, in BurnPage.");
    this.listData = [];
    const limit = 50;
    const GET_ASSETS_API_TEST = "https://rinkeby-api.opensea.io/api/v1/assets?owner=" + window.account + "&asset_contract_address=" + ITEM_CONTRACT + "&order_direction=desc&limit=" + limit + "&offset=";
    //    const GET_ASSETS_API_TEST = "http://192.168.11.16/nft/api/getasset.php?owner="+window.account+"&contract="+ITEM_CONTRACT+"&limit="+limit+"&offset=";
    const GET_ASSETS_API_LIVE = "https://api.opensea.io/api/v1/assets?owner=" + window.account + "&asset_contract_address=" + ITEM_CONTRACT + "&order_direction=desc&limit=" + limit + "&offset=";
    const GET_ASSETS = APPLICATION_ENV === 'development' ? GET_ASSETS_API_TEST : GET_ASSETS_API_LIVE;

    let offset: number = 0;

    const axiosInstance = axios.create({
      headers: { 'X-API-KEY': 'e33abfd758b4455792aed82b8f8bc786' },
      timeout: 5 * 60 * 1000      
    });
    while (true) {
      const res = await axiosInstance.get(GET_ASSETS + offset);
//      console.log(res);
      //return res.data.assets;
      if (res.status === 200) {
        if (res.data.assets.length === 0) {
          return;
        }

        this.listData = this.listData.concat(res.data.assets);
//        console.log(this.listData);
        ReactDOM.render(<ItemsList onselect={this.select_item} default_selected={this.state.selected_tokenId[selected]} disable_selected={this.getDisableSelect(selected)} listData={this.listData} />, document.getElementById('items_list'));
        offset += limit;
      }
    }
  }

  getSelectedId(selected: number) {
    if (selected === Burn.selected_1) {
      return "selected_1";
    }

    return "selected_2";
  }

  SelectItem: React.FC<{
    selected: number
  }> = ({ selected }) => {
    if (!window.account) {
      return (
        <div></div>
      );
    }
    if (this.state.selected_tokenId[selected] === 0) {
//      console.log("selected1:"+this.state.selected_tokenId[Burn.selected_1]);
//      console.log("selected2:"+this.state.selected_tokenId[Burn.selected_2]);
//      console.log("no selected");
      return (
        <div id={this.getSelectedId(selected)} onClick={(e) => this.view_select_items(e, selected)}>
          <div className="noselected"> no selected </div>
        </div>
      );
    } else {
      const image_url: string = this.state.selected_image_url[selected].slice();
      if (image_url.slice(-4) === ".mp4") {
        //console.log("video tag");
        return (
          <div id={this.getSelectedId(selected)} onClick={(e) => this.view_select_items(e, selected)}>
            <video className="selected_img" src={image_url}>  </video>
          </div>
        );
      } else {
        //console.log("image tag:"+image_url);
        const image_path = "url(" + image_url + ")";
        return (
          <div id={this.getSelectedId(selected)} onClick={(e) => this.view_select_items(e, selected)}>
            <div className="selected_img" style={{ backgroundImage: image_path }}> </div>
          </div>
        );

      }
    }
  }

  Connected= () => {
//    console.log(window.account);
    if (!window.account) {
//      console.log(this);
      return (
        <div className="connect2b" onClick={() => this.connectWallet()}> </div>
      );
    } else {
      return (
        <div></div>
      );
    }
  }

  connectWallet(): void {
    if (typeof window.ethereum !== 'undefined') {
      console.log('Metamask is installed!');
    } else {
      alert("Please install matamask extenttion. Or Open this page metaMask browser.");
      return;
    }
    // console.dir("call getAccount");
    this.getAccount();
  }

  Selected() {
    return (
      <div id="selected_items">
        <this.SelectItem selected={Burn.selected_1} />
        <this.SelectItem selected={Burn.selected_2} />
      </div>

    );
  };

  BurnButton = () => {
    if (!window.account) {
      return (
        <div></div>
      );
    }
    const token_1 = this.state.selected_tokenId[Burn.selected_1];
    const token_2 = this.state.selected_tokenId[Burn.selected_2];
    if (token_1 !== 0 && token_2 !== 0 && token_1 !== token_2) {
      return (
        <div id="burn_button" className="button" onClick={this.clickBurnButton}>
          <div id="burn_button_outer" className="button_outer">
            <div id="burn_button_inner" className="button_inner">
              <span className="button_text">Burn</span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div id="burn_button" className="button">
        <div id="burn_button_outer" className="button_outer">
          <div id="burn_button_inner" className="button_inner">
          <span className="button_text">Burn</span>
          </div>
        </div>
        <div id="burn_button_disable">
        </div>
      </div>
    );
  }

  ConfirmMessage = () => {
    if (this.state.show_confirm) {
      return (
        <div id="confirm_message">
          <div id="confirm_message_back" className="full_back" onClick={() => this.confirm(false)}>
          </div>

          <div id="confirm_message_text" onClick={() => this.confirm(false)}>
            YOME will be gone and never coming back.<br/>Are you ok ?
          </div>

          <div id="confirm_button" className="button" onClick={() => this.confirm(true)}>
            <div id="confirm_button_outer" className="button_outer">
              <div id="confirm_button_inner" className="button_inner">
              <span className="button_text">OK</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div></div>
      );
    }
  }

  Burning = () => {
    if (this.state.burning) {
/*      
      const SCAN_TEST = "https://rinkeby.etherscan.io/tx/";
      const SCAN_LIVE = "https://etherscan.io/tx/";
      const SCAN = APPLICATION_ENV === 'development' ? SCAN_TEST : SCAN_LIVE;
*/
      return (
        <div id="burning" >
          <div className="full_back">
          </div>
          <div id="burning_image">
          </div>
          {/*<div id="transfer_txhash" onClick={()=>{window.open(SCAN+this.state.transfer_txhash) }}>
            tx_hash: {this.state.transfer_txhash}
      </div>*/}
        </div>
      );
    } else {
      return (
        <div></div>
      );
    }
  }

  BurnResult = () => {
    if (this.state.burn_result) {
      const SCAN_TEST = "https://rinkeby.etherscan.io/tx/";
      const SCAN_LIVE = "https://etherscan.io/tx/";
      const SCAN = APPLICATION_ENV === 'development' ? SCAN_TEST : SCAN_LIVE;

      const token_id = this.state.new_comer.token_id;

      const image_uri = "url(" + this.state.new_comer.image_url + ")";
      const classname = token_id ? "" : "loading";
      if (image_uri.slice(-4) === ".mp4") {
        return (
          <div id="burning_result" >
            <div id="burning_result_back" className="full_back">
            </div>

            <div id="burn_new_comer_image" className={classname} onClick={() => { this.openDetail(token_id) }}>
            <video src={image_uri} autoPlay loop muted>  </video>
            </div>

            <div id="mint_txhash" onClick={() => { window.open(SCAN + this.state.mint_txhash) }}>
              tx_hash: {this.state.mint_txhash}
            </div>

            <div id="burn_message_text">
              You got new YOME
            </div>

            <div id="result_button_outer" className="button" onClick={this.burnEnd}>
              <div className="button_outer">
                <div className="button_inner">
                <span className="button_text">OK</span>
                </div>
              </div>
            </div>
          </div>
        );
      }else{
        const image_uri = "url(" + this.state.new_comer.image_url + ")";
        return(
          <div id="burning_result" >
          <div id="burning_result_back" className="full_back">
          </div>
  
          <div id="burn_new_comer_image" className={classname} onClick={() => { this.openDetail(token_id) }} style={{ backgroundImage: image_uri }}>
          </div>
  
          <div id="mint_txhash" onClick={() => { window.open(SCAN + this.state.mint_txhash) }}>
            tx_hash: {this.state.mint_txhash}
          </div>
  
          <div id="burn_message_text">
            You got new YOME
          </div>
  
          <div id="result_button_outer" className="button" onClick={this.burnEnd}>
            <div className="button_outer">
              <div className="button_inner">
              <span className="button_text">OK</span>
              </div>
            </div>
          </div>
        </div>
        );
      }
    } else {
      return (
        <div></div>
      );
    }
  }

  ErrorOccured = () => {
    if (this.state. error) {
      return (
        <div id="error_message">
          <div id="error_message_back" className="full_back" onClick={() => this.error_occured(false)}>
          </div>

          <div id="error_message_text" onClick={() => this.error_occured(false)}>
            An error has ocurred.<br/>
            Please contact us, if you need any help.
          </div>

          <div id="error_button" className="button" onClick={() => this.error_occured(true)}>
            <div id="error_button_outer" className="button_outer">
              <div id="error_button_inner" className="button_inner">
              <span className="button_text">Discord</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div></div>
      );
    }
  }


/*

      if (image_url.slice(-4) === ".mp4") {
        //console.log("video tag");
        return (
          <div id={this.getSelectedId(selected)} onClick={(e) => this.view_select_items(e, selected)}>
            <video className="selected_img" src={image_url}>  </video>
          </div>
        );
      } else {
        //console.log("image tag:"+image_url);
        const image_path = "url(" + image_url + ")";
        return (
          <div id={this.getSelectedId(selected)} onClick={(e) => this.view_select_items(e, selected)}>
            <div className="selected_img" style={{ backgroundImage: image_path }}> </div>
          </div>
        );

      }

*/


  componentDidMount() {
  }

  render() {

    return (
      <div id="burn">
        <div id="burn-main">
          {/**
          <div id="selected_items">
          </div>
           */}
          <div id="selected_items">
            <this.SelectItem selected={Burn.selected_1} />
            <this.SelectItem selected={Burn.selected_2} />
          </div>

          <this.Connected />
          <this.BurnButton />
          <this.ConfirmMessage />
          <this.Burning />
          <this.BurnResult />
          <this.ErrorOccured />

          <div id="items" onClick={this.close_select_items} style={{ display: this.state.visible_select_items }}>
            <div id="items_back" >

            </div>
            <div id="items_for" >
              <div id="items_list">

              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }

  view_select_items = (e: MouseEvent, selected: number) => {
    if (e.isDefaultPrevented()) {
      return;
    }
    e.preventDefault();
    //render(<Exchange />, document.getElementById('root'));
    this.setState({ selectting: selected });
//    console.log(selected);
    
//    console.log("selected1:"+this.state.selected_tokenId[Burn.selected_1]);
//    console.log("selected2:"+this.state.selected_tokenId[Burn.selected_2]);

    //    ReactDOM.render(<ItemsList onselect={this.select_item} default_selected={this.state.selected_tokenId[selected]} disable_selected={this.getDisableSelect(selected)} update={false} listData={this.listData} />,document.getElementById('items_list'));
    this.setState({ visible_select_items: 'inline-block' });
    if (this.listData.length === 0) {
//      console.log("load data.");
      this.loadData(selected);
    }else{
//      console.log("not load data.");
      ReactDOM.render(<ItemsList onselect={this.select_item} default_selected={this.state.selected_tokenId[selected]} disable_selected={this.getDisableSelect(selected)} listData={this.listData} />,document.getElementById('items_list'));
    }
  }

  getDisableSelect(selectId: number) {
    if (selectId === Burn.selected_1) {
      return this.state.selected_tokenId[Burn.selected_2];
    } else {
      return this.state.selected_tokenId[Burn.selected_1];
    }
  }

  close_select_items = (e: MouseEvent) => {
    if (e.isDefaultPrevented()) {
      return;
    }
    e.preventDefault();

//    console.log("close_select_items");
    this.select_item(0, "");
  }

  select_item = (token_id: number, image_url: string) => {
//    console.log("token_id:" + token_id);
//    console.log("image_url:" + image_url);
    const token_ids: number[] = this.state.selected_tokenId.slice();
    token_ids[this.state.selectting] = token_id;
    this.setState({ selected_tokenId: token_ids });

    const image_urls: string[] = this.state.selected_image_url.slice();
    image_urls[this.state.selectting] = image_url;
    this.setState({ selected_image_url: image_urls });

    this.setState({ visible_select_items: 'none' });
    ReactDOM.render(<Burn />, document.getElementById('f11'));

    /*
        ReactDOM.render(
          <this.Selected />,
          document.getElementById('selected_items')
        );    
    */
  }

  clickBurnButton = () => {
//    console.log("aaa");
    this.setState({ show_confirm: true });
    ReactDOM.render(<Burn />, document.getElementById('f11'));
  }

  getAssets = async (tokenId: number) => {
    const GET_ASSETS_API_TEST = "https://rinkeby-api.opensea.io/api/v1/assets?asset_contract_address=" + ITEM_CONTRACT + "&token_ids=";
    //    const GET_ASSETS_API_TEST = "http://192.168.11.16/nft/api/getasset.php?contract="+ITEM_CONTRACT+"&token_ids=";
    const GET_ASSETS_API_LIVE = "https://api.opensea.io/api/v1/assets?asset_contract_address=" + ITEM_CONTRACT + "&token_ids=";
    const GET_ASSETS = APPLICATION_ENV === 'development' ? GET_ASSETS_API_TEST : GET_ASSETS_API_LIVE;

    const axiosInstance = axios.create({
      headers: { 'X-API-KEY': 'e33abfd758b4455792aed82b8f8bc786' },
      timeout: 5 * 60 * 1000      
    });
    const _sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    for (let i = 0; i < 20; i++) {
      try{
//        const result = await axiosInstance.get(GET_ASSETS + tokenId+"&time="+i);
        const result = await axiosInstance.get(GET_ASSETS + tokenId);
//        console.log(result);
        if (result.status === 200 && result.data) {
          if (i < 20 - 1) {//not final
            try{
              if(result.data.assets[0].image_preview_url == null){
//                console.log("**** bug fix !! ****");
//                await this.wait(3);
                await _sleep(5000);

                continue;
              }
            }catch(error){
//              console.log("**** bug fix !!! ****");
//                await this.wait(3);
              await _sleep(5000);
              continue;
            }
          }
          const json = result.data.assets[0];
          return json;
        }
      }catch(error){
        console.log(error);
//        await this.wait(3);
        await _sleep(5000);

      }
    }

    return null;
  }

  getTokenID = async (transactionHash: string) => {
    //    console.log(transactionHash);

    const ETHERSCAN_URL_TEST = "https://rinkeby.etherscan.io/tx/"
    const ETHERSCAN_URL_LIVE = "https://etherscan.io/tx/"
    const ETHERSCAN_URL = APPLICATION_ENV === 'development' ? ETHERSCAN_URL_TEST : ETHERSCAN_URL_LIVE;

    //console.log(ETHERSCAN_URL + transactionHash);

    const result = await axios.get(ETHERSCAN_URL + transactionHash,{timeout: 5 * 60 * 1000});
    //  console.log(result.data);
    if (result.status !== 200 || !result.data) {
      return 0;
    }

    const html = String(result.data);
    //  console.log(html);

    const pos = html.indexOf("ERC-721 TokenID", 0);
    if (pos === -1) {
      return 0;
    }
    const begin = result.data.indexOf("'>", pos);
    const end = result.data.indexOf("</", pos);
    const token_id = result.data.slice(begin + 2, end);
    /*  
      console.log("pos:"+pos+", begin:"+begin+", end:"+end+", tokenID:"+token_id);
      const pos2 = html.indexOf("Status:",0);
      console.log("pos2:"+pos2);
      console.log("**********");
      console.log(html.substring(26050, 26070));
      console.log("**********");
    */
    return parseInt(token_id);
  }

  wait = async (second: number) => {
    new Promise(
      (resolve) => {
        setTimeout(resolve, second * 1000);
      }
    );
  };


  removeItem = (token_id: string) => {
//    console.log("remove: " + token_id);
    for (let i = 0; i < this.listData.length; i++) {
      const item = this.listData[i];
      if (item["token_id"] === token_id) {
        //this.listData = this.listData.splice(i,1);
        this.listData.splice(i, 1);
//        console.log("removed: " + token_id);
//        console.log(this.listData);
        break;
      }
    }
  }
  /*
    addItem = (token_id:number, image_path:string) => {
      const item: any = {};
      item["image_preview_url"] = image_path;
      item["token_id"] = token_id;
    }
  */
  addItem = (item: any) => {
    this.listData.unshift(item);
    //    console.log("add: "+item);
    //    console.log(item);
  }

  confirm = async (confirm: boolean) => {
//    console.log("bbb");
    if (confirm) {
      this.setState({ show_confirm: false });
      this.setState({ burning: true });
    } else {
      this.setState({ show_confirm: false });
      return;
    }

    const log = new LogTransaction(window.account, APPLICATION_ENV !== 'development');
    //Send

    const assets: Array<{ tokenId: string; tokenAddress: string }> = [
      { tokenId: String(this.state.selected_tokenId[Burn.selected_1]), tokenAddress: ITEM_CONTRACT },
      { tokenId: String(this.state.selected_tokenId[Burn.selected_2]), tokenAddress: ITEM_CONTRACT }
    ]

//    console.log("assets:");
//    console.log(assets);

    const SENDER = window.account;
    //    const SENDER = "";
    const RECEIVER = CEMETERY_ADDRESS;
    //    const RECEIVER = "";
    const transfer = {
      "token_IDS": [
        String(this.state.selected_tokenId[Burn.selected_1]),
        String(this.state.selected_tokenId[Burn.selected_2])
      ],
      "toAddress": RECEIVER
    }
    log.log(LogTransaction.BEGIN_TRANSFER, transfer);

//    console.log("send " + SENDER + " to " + RECEIVER);

    try {
      const res = await window.opensea.transferAll({
        assets: assets,
        fromAddress: SENDER,
        toAddress: RECEIVER
      });
      this.setState({ transfer_txhash: res });
      log.log(LogTransaction.END_TRANSFER, transfer, {
        "txhash": res
      });
    } catch (error) {
      console.log(error);
      log.log(LogTransaction.ERROR_TRANSFER, transfer, {
        "error": error.name + " " + error.message
      });

      this.setState({ burning: false });
      ReactDOM.render(<Burn />, document.getElementById('f11'));

      return;
    }

    this.removeItem(assets[0].tokenId);
    this.removeItem(assets[1].tokenId);

    ReactDOM.render(<Burn />, document.getElementById('f11'));
    //    axios.defaults.headers.get['Access-Control-Allow-Origin'] = 'http:/192.168.11.227:8000/';    
    // MINT
    /*
    const mint = {
      "toAddress": SENDER
    }
    */
    //    log.log(LogTransaction.BEGIN_MINT,mint);
    const API_MINT_TEST = "http://192.168.11.16/nft/api/mint.php?address=";
    //    const API_MINT_LIVE = "https://crypto.softhouse-seal.com/api/mint.php?address=";
    const API_MINT_LIVE = "https://crypto.softhouse-seal.com/api/mint.php?";
    //    const API_MINT = APPLICATION_ENV === 'development' ? API_MINT_TEST : API_MINT_LIVE;
    const API_MINT = API_MINT_LIVE;
    //    const res = await axios.get(API_MINT + window.account);
    try{
      const res = await axios.get(API_MINT + log.getParamString(),{timeout: 5 * 60 * 1000});
      if (res.status !== 200) {
        //      log.log(LogTransaction.ERROR_MINT,mint,{"error": res.status + " " + res.statusText});
        ReactDOM.render(<ItemsList onselect={this.select_item} default_selected={0} disable_selected={0} listData={this.listData} />, document.getElementById('items_list'));
        this.setState({ burning: false });
        this.setState({ error: true });
    
        return;
      }
//      console.log(res.status);
  
//      console.log(res.data);
      //    const res2 = JSON.parse(res.data);
      if (res.data.result === "error") {
        console.log(res.data.detail);
        // :TODO display mint error
        ReactDOM.render(<ItemsList onselect={this.select_item} default_selected={0} disable_selected={0} listData={this.listData} />, document.getElementById('items_list'));
        this.setState({ burning: false });
        this.setState({ error: true });
        return;
      }
  
      this.setState({ mint_txhash: res.data.txhash });

      const url = "https://crypto.softhouse-seal.com/api/updatesupabl.php";
      axios.get(url,{timeout: 5 * 60 * 1000}).then(res => {
        if (APPLICATION_ENV === 'development') {
          console.log(res.data);
        }
      });
  
    }catch(error){
      ReactDOM.render(<ItemsList onselect={this.select_item} default_selected={0} disable_selected={0} listData={this.listData} />, document.getElementById('items_list'));
      this.setState({ burning: false });
      this.setState({ error: true });
      return ;
    }
    //    log.log(LogTransaction.END_MINT,mint,{"txhash": res.data.txhash});

    //      const result = JSON.parse(res.data);
    this.setState({ burning: false });
    this.setState({ burn_result: true });
    ReactDOM.render(<Burn />, document.getElementById('f11'));




    let token_id = 0;
    for (var i = 0; i < 20; i++) {
      const _sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
      await _sleep(5000);
      token_id = await this.getTokenID(this.state.mint_txhash);
      //        console.log("token_id:"+token_id);
      if (token_id) {
//        console.log(token_id);
        break;
      }
//      console.log("...");
    }

    if (token_id) {
      const asset = await this.getAssets(token_id);
      //      console.log(asset);
      /*        
            const asset: any = await window.opensea.api.getAsset({
              tokenAddress: ITEM_CONTRACT,
              tokenId: token_id
            });
      */
//      console.log(asset);
//      console.log(asset.image_preview_url);
      this.setState({ new_comer: { token_id: token_id, image_url: asset.image_preview_url } });
      //      console.log(this.listData);
      this.addItem(asset);
      //      console.log(this.listData);
      ReactDOM.render(<Burn />, document.getElementById('f11'));
    } else {
//      console.log("AAA");
    }

    //    ReactDOM.render(<ItemsList onselect={this.select_item} default_selected={0} disable_selected={0} update={true}/>,document.getElementById('items_list'));
    ReactDOM.render(<ItemsList onselect={this.select_item} default_selected={0} disable_selected={0} listData={this.listData} />, document.getElementById('items_list'));

    /*
        setTimeout(()=>{
          this.setState({burning: false});
          this.setState({burn_result: true});
          ReactDOM.render(<Burn />,document.getElementById('f11'));
        },3000);
    //*/
  }

  burnEnd = () => {
    this.setState({ burn_result: false });
    this.setState({ selected_tokenId: [0, 0] });
    this.setState({ selected_image_url: ["", ""] });
    this.setState({ new_comer: { token_id: 0, image_url: "assets/css/images/spin.svg" } });
    ReactDOM.render(<Burn />, document.getElementById('f11'));
  }

  openDetail = (token_id: number) => {
//    console.log(token_id);

    const OPENSEA_DETAIL_TEST = "https://testnets.opensea.io/assets/" + ITEM_CONTRACT + "/"+token_id;
    const OPENSEA_DETAIL_LIVE = "https://opensea.io/assets/" + ITEM_CONTRACT + "/"+token_id;
    const OPENSEA_DETAIL = APPLICATION_ENV === 'development' ? OPENSEA_DETAIL_TEST : OPENSEA_DETAIL_LIVE;

    window.open(OPENSEA_DETAIL);
    
  }

  error_occured = (discord :boolean)=>{
    if(discord){
      window.open(DISCORD_URL);
    }else{
      this.setState({ error: false });
      this.setState({ selected_tokenId: [0, 0] });
      this.setState({ selected_image_url: ["", ""] });
      this.setState({ new_comer: { token_id: 0, image_url: "assets/css/images/spin.svg" } });
      ReactDOM.render(<Burn />, document.getElementById('f11'));
    }
  };
  

  connect2 = (e: MouseEvent) => {
    // console.dir("ConnecButton2");
    // alert("c-------2");
    if (typeof window.ethereum !== 'undefined') {
      console.log('Metamask is installed!');
    } else {
      alert("Please install matamask extenttion. Or Open this page metaMask browser.");
      return;
    }
    // console.dir("call getAccount");
    this.getAccount();
  }

  getAccount = async () => {
    console.dir("getAccount");
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch (error) {
      if (error.code === -32002) {
        // alert("getAccount error. -32002");
        alert('Connenting Metamask. Please Check metamask browser Menu');
      }
      console.dir("getAccount error.");
      console.dir(error);
      // alert("getAccount error.");
    }
    // const account = accounts[0];
    // this.account1 = account;
    window.location.reload();
  }


}

export default Burn;