import axios from "axios";

declare global {
    interface Window { ethereum: any; opensea: any; account: any;}
}


class LogTransaction {
    public static readonly BEGIN_TRANSFER :string = "begin_transfer";
    public static readonly END_TRANSFER: string = "end_transfer";
    public static readonly ERROR_TRANSFER: string = "error_transfer";

    public static readonly BEGIN_MINT: string = "begin_mint";
    public static readonly END_MINT: string = "end_mint";
    public static readonly ERROR_MINT: string = "error_mint";

    private address :string;
    private id :string;
    private isLive: boolean;

    constructor (address :string, isLive :boolean ){
        this.address = address;
        const date = new Date();
        this.id = String(date.getTime());
        this.isLive = isLive;
    }

    async log(operation: string, option: any , add: any = null) {
        const base :any = {};
        base.log_id = this.id;
        base.address = this.address;
        base.network = this.getNetwork();
        base.operation = operation;

        if(add != null){
            option = Object.assign(option,add);
        }

        const merged = Object.assign(base,option);

        console.log(merged);
/*
  `network` varchar(10) NOT NULL DEFAULT '' COMMENT 'idをsha256した文字列',
  `txhash` varchar(80) NOT NULL DEFAULT '' COMMENT 'トランザクションハッシュ',
  `address` varchar(64) NOT NULL DEFAULT '' COMMENT 'ETH ウォレットアドレス',
  `operation` varchar(32) NOT NULL DEFAULT '' COMMENT '実行した処理',
  `option` text COMMENT '付加情報のjson',
*/
      
        const LOG_API = "https://crypto.softhouse-seal.com/api/burn_log.php";
        const PARAMS = [
            "log_id="+this.id,
            "network="+this.getNetwork(),
            "address="+this.address,
            "operation="+operation,
            "sub_params="+JSON.stringify(option),
        ];
        if (option.hasOwnProperty('txhash')){
            PARAMS.push("txhash="+option.txhash);
        }

        const result = await axios.get(LOG_API +"?" + PARAMS.join("&"));
//        console.log(result);
    }

    getParamString() : string{
        const PARAMS = [
            "log_id="+this.id,
            "network="+this.getNetwork(),
            "address="+this.address,
        ];
        return PARAMS.join("&");
    }

    getNetwork(): any {
        if(this.isLive){
            return "live"; 
        }
        return "rinkeby"; 
    }
}

export default LogTransaction;