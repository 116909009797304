import React from 'react';
// import { ASSET_URL, CHOSEN_THEME } from './constants'
// import logo from './static/images/logo.png';
import './static/styles/App.css';
// import footer from './static/images/footer.png';
import { iframeResizer } from 'iframe-resizer'
import './static/styles/App.css';
// opensea-js
// import * as Web3 from 'web3'
// import Web3 from 'web3'
import { OpenSeaPort, Network } from 'opensea-js'
import { OrderSide } from 'opensea-js/lib/types';
import BigNumber from 'bignumber.js'
import axios from "axios";
import { Line } from 'rc-progress';
import ReactDOM from 'react-dom';
import Burn from './BurnPage';

declare global {
    interface Window { ethereum: any; opensea: any; account: any;}
}

// console.dir(window.ethereum.isMetaMask);
if (!window.ethereum || !window.ethereum.isMetaMask) {
  // alert('MetaMaskをインストールしてください。');
}

const APPLICATION_ENV = process.env.REACT_APP_APPLICATION_ENV!;
console.log(APPLICATION_ENV);

//const ITEM_CONTRACT_TEST = "0x5ddb47ee5a8c53c6a691de7db99a53e6c63e3748";
//const ITEM_CONTRACT_LIVE = "0x95ae387d8da3c218054772f682b662ef4fca7b8e";
//const ITEM_CONTRACT = APPLICATION_ENV === 'development' ? ITEM_CONTRACT_TEST : ITEM_CONTRACT_LIVE;

/*
// const provider = new Web3.providers.HttpProvider('https://mainnet.infura.io')
const provider = new Web3.providers.HttpProvider('https://rinkeby.infura.io')
const seaport = new OpenSeaPort(provider, {
  // networkName: Network.Main
  networkName: Network.Rinkeby
});
// console.dir(provider);
// console.dir(seaport);
*/

// const accountAddress = "0x87EE938bF0D21055756FCe84FBe4A85a3E48A0F6" // 売り者
// const accountAddress = "0x560704fdb4905a3649660dd3a626b8b3d396ea66" //cont
// const buyerAddress = "0x84b237326149E881F1a1aDAe78fD5990D829A2a3" // 買い
    

class App extends React.Component<{}, { buyAccount: string, creatingOrder: boolean, bnum: number, price: string, message: string, showFlag: string, supply: number, buyable: number, percent: number }> {
  // public account1: string  = '';
  // public price: number = 0;
  public provider: any = {};
  public seaport: any = {};
  public contractAddress: string = '';
  public supply: number = 0; // 今まで合計発行枚数を取得
  public buyable: number = 0; // あと何個買えるかを取得する（同じ値段で）
  public baseOptionId: number = 30; // 購入するコントラクトの段階
//  public baseOptionId: number = 10; // デバッグ用

  constructor(props: {}) {
    super(props);
    this.state = {
      buyAccount: ''
    , creatingOrder: false
    , bnum: 1
    , price: ''
    , message: '<b>Tap The Card to Get YOME</b>'
    , showFlag: 'inline'
    , supply: 0
    , buyable: 0
    , percent: 0
    };
    // to use this in async
    this.getOrderAsync = this.getOrderAsync.bind(this);
    this.getAsset = this.getAsset.bind(this);
    this.updateSalse = this.updateSalse.bind(this);
    this.incBar = this.incBar.bind(this);
  }

  private tm!: number;

  componentDidMount() {
    iframeResizer({ log: false, checkOrigin: false }, '#opensea-iframe')

    this.updateSalse();

    if (!window.ethereum || !window.ethereum.isMetaMask) {
        // alert('MetaMaskをインストールしてください。');
    }

    if (!window.ethereum) {
        // console.dir("no metaMask");
        return;
    }

    window.ethereum.request({method: 'eth_accounts'}).then((accounts: any) => {
      if (accounts.length > 0) {
        // 接続済み
        const account = accounts[0];
        // this.account1 = account;
        this.setState({buyAccount: account});
        this.setState({showFlag: 'none'}); // conncetボタン隠す
        window.account = account;
        ReactDOM.render(<Burn />, document.getElementById('f11'));

console.dir("eth account: " + this.state.buyAccount + " connected");
// alert("connected " + this.state.buyAccount);
// console.dir(this.state);
// console.dir(this.state.buyAccount);
        // form1.eth.value = account;
        // eeb.innerHTML = 'Connected';
        // showNet(window.ethereum.chainId);
        // getProv();
      } else {
        // 未ログイン
        console.dir("not connected.");
      }
    })

    this.provider = window.ethereum; 
    if (APPLICATION_ENV === 'development') {
      this.seaport = new OpenSeaPort(this.provider, {
        // networkName: Network.Main
        networkName: Network.Rinkeby,
        apiKey: "e33abfd758b4455792aed82b8f8bc786"
      });
      window.opensea = this.seaport;
      //this.contractAddress = "0x560704fdb4905a3649660dd3a626b8b3d396ea66"; // ikeda テスト用
      this.contractAddress = "0xd539845290ff6ce7926958837bab83ee70769a41"; // 田中テスト用
      this.baseOptionId = 10;
// console.log("130 " + APPLICATION_ENV);
    } else {
      // マジ用設定
      this.seaport = new OpenSeaPort(this.provider, {
        networkName: Network.Main,
        // networkName: Network.Rinkeby
        apiKey: "e33abfd758b4455792aed82b8f8bc786"
      });
      // マジ用 0x48CC0a6FBf919EC99160B00Ff3b31b30247aE640
      this.contractAddress = "0x48CC0a6FBf919EC99160B00Ff3b31b30247aE640";
      window.opensea = this.seaport;
// console.log(" 139 " + APPLICATION_ENV);
// console.log(" 140 " + this.contractAddress);
    }
    // console.dir(this.contractAddress);
    // this.contractAddress = "";

    // this.getAsset(); // 価格情報が含まれない
    this.getOrderPre();

    // ダメ window.document.getElementById('cButton').style.display = "none";
  }

  render() {
    const { percent } = this.state; 
    return (
      <div className="App">
        <main className="App-main">

          <b className="sales_status" style={{ color: 'blue' }}>Sales Status: {this.state.supply} / 5020</b>
          <div id="sales_progress_outer">
            <b className="sales_status" style={{ color: 'blue' }}>Sales Status: {this.state.supply} / 5020</b><br />
            <div id="sales_progress_frame">
              <div id="sales_progress" style={{ margin: '0 auto' }}>
                <Line strokeWidth={4} trailWidth={4} percent={percent}/>
              </div>
            </div>
          </div>
          <br />
          {/*}
          <span dangerouslySetInnerHTML={{__html: this.state.message}}></span><br />
{/*          
          <a href="#!" onClick={this.getOrder}><img className="buyButton" width="250px" src="/images/card_ura.png" alt="buy" /></a><br />
*/}          
          <a href="#!" id="buyButton" onClick={this.getOrder}>
            <div className="buyButton2" >
            </div>
          </a>

          <div id="price">
            {this.state.price}
          </div>
          <div id="connect2b" onClick={this.connect2} style={{ display: this.state.showFlag }}> </div>

          <div id="wallet">
          {this.state.buyAccount}
          {/* 
          <a onClick={this.getAsset}>getAsset</a><br />
          <a onClick={this.getOrder}>getOrder</a><br />
          */}
          </div>
          
          <div id="buy_count">
            <div id="inc_buy" onClick={this.incNum}></div>
            <input type="number" name="bnum" id="bnum" value={this.state.bnum} min={1} max={10} size={4} onChange={this.handleChange} style={{ display: 'inline-block' }} />
            <div id="dec_buy" onClick={this.decNum}></div>
          </div>

        </main>
      </div>
    );
  }

  getEth() {
    console.dir("getEth");
    return 1;
    //return this.account1;
  }

  async getAsset() {
    console.dir("getAseet");
    const asset: any = await this.seaport.api.getAsset({
      tokenAddress: this.contractAddress
      // tokenAddress: "0x560704fdb4905a3649660dd3a626b8b3d396ea66"
    , tokenId: "1"
  })
    console.dir(asset);
    return;
  }

  // オーダー情報から値段を取得する
  getOrderPre = () => {
    this.getOrderPreAsync();
  }
  async getOrderPreAsync() {
    let Order: any = {};

    // 購入可能数取得
    const url = "https://crypto.softhouse-seal.com/api/getsupabl.php";
    try {
      const res = await axios.get(url);
      if (APPLICATION_ENV === 'development') {
        console.log(res.data);
      }
      this.supply = res.data.supply;
      this.buyable = res.data.buyable;
      this.setState({supply: this.supply});
      // + で数値型に変換
      if (this.baseOptionId < +res.data.range) {
        console.log("base Change to " + res.data.range);
        this.baseOptionId = +res.data.range;
        if (APPLICATION_ENV === 'development') {
          this.baseOptionId = 10;//デバッグ用
        }
        console.log(this.baseOptionId);
    }
// console.dir(this.state.supply);

    } catch(error) {
      console.dir(error.message);
    };

    if (APPLICATION_ENV === 'development') {
    console.dir("getOrderPre " + this.baseOptionId);
    } else {
      console.dir("getOrderPre " + this.baseOptionId);
    }

    // this.baseOptionId = 0;

    try {
      Order = await this.seaport.api.getOrder({
        asset_contract_address: this.contractAddress
        // asset_contract_address: "0x560704fdb4905a3649660dd3a626b8b3d396ea66"
      , token_id: this.baseOptionId
      , side: OrderSide.Sell
      })
      const pri = Order.currentPrice.div(new BigNumber(10).pow(18));
      this.setState({price: pri.toString()});

      console.dir("Get order OK");

      return;

    } catch(error) {
      if (APPLICATION_ENV === 'development') {
        console.dir(error);
        console.dir("No sell order Next");
      }
      // alert("or in preparation.");
      // return;
    }

    this.baseOptionId += 10;
    if (this.baseOptionId > 89) {
      console.dir("No range" + this.baseOptionId);
      return;
    }
    console.dir(this.baseOptionId);

    try {
      Order = await this.seaport.api.getOrder({
        asset_contract_address: this.contractAddress
      , token_id: this.baseOptionId
      , side: OrderSide.Sell
      })
      const pri = Order.currentPrice.div(new BigNumber(10).pow(18));
      this.setState({price: pri.toString()});
      return;

    } catch(error) {
      if (APPLICATION_ENV === 'development') {
        console.dir(error);
        console.dir("No sell order Next");
      }
      // alert("in preparation.");
      // return;
    }

    this.baseOptionId += 10;
    if (this.baseOptionId > 89) {
      console.dir("No range");
      return;
    }
    console.dir(this.baseOptionId);

    try {
      Order = await this.seaport.api.getOrder({
        asset_contract_address: this.contractAddress
      , token_id: this.baseOptionId
      , side: OrderSide.Sell
      })
      const pri = Order.currentPrice.div(new BigNumber(10).pow(18));
      this.setState({price: pri.toString()});
      return;

    } catch(error) {
      if (APPLICATION_ENV === 'development') {
        console.dir(error);
        console.dir("No sell order Next");
      }
      // alert("or in preparation.");
      // return;
    }

    this.baseOptionId += 10;
    if (this.baseOptionId > 89) {
      console.dir("No range");
      return;
    }
    console.dir(this.baseOptionId);

    try {
      Order = await this.seaport.api.getOrder({
        asset_contract_address: this.contractAddress
      , token_id: this.baseOptionId
      , side: OrderSide.Sell
      })
      const pri = Order.currentPrice.div(new BigNumber(10).pow(18));
      this.setState({price: pri.toString()});
      return;

    } catch(error) {
      if (APPLICATION_ENV === 'development') {
        console.dir(error);
        console.dir("No sell order Next");
      }
      // alert("in preparation.");
      // return;
    }

    this.baseOptionId += 10;
    if (this.baseOptionId > 89) {
      console.dir("No range");
      return;
    }
    console.dir(this.baseOptionId);

    try {
      Order = await this.seaport.api.getOrder({
        asset_contract_address: this.contractAddress
      , token_id: this.baseOptionId
      , side: OrderSide.Sell
      })
      const pri = Order.currentPrice.div(new BigNumber(10).pow(18));
      this.setState({price: pri.toString()});
      return;

    } catch(error) {
      if (APPLICATION_ENV === 'development') {
        console.dir(error);
        console.dir("No sell order Next");
      }
      // alert("in preparation.");
      // return;
    }

    this.baseOptionId += 10;
    if (this.baseOptionId > 89) {
      console.dir("No range");
      return;
    }
    console.dir(this.baseOptionId);

    try {
      Order = await this.seaport.api.getOrder({
        asset_contract_address: this.contractAddress
      , token_id: this.baseOptionId
      , side: OrderSide.Sell
      })
      const pri = Order.currentPrice.div(new BigNumber(10).pow(18));
      this.setState({price: pri.toString()});
      return;

    } catch(error) {
      if (APPLICATION_ENV === 'development') {
        console.dir(error);
        console.dir("No sell order Next");
      }
      // alert("in preparation.");
      // return;
    }

    this.baseOptionId += 10;
    if (this.baseOptionId > 89) {
      console.dir("No More range");
      return;
    }
    console.dir(this.baseOptionId);

    try {
      Order = await this.seaport.api.getOrder({
        asset_contract_address: this.contractAddress
      , token_id: this.baseOptionId
      , side: OrderSide.Sell
      })
      const pri = Order.currentPrice.div(new BigNumber(10).pow(18));
      this.setState({price: pri.toString()});
      return;

    } catch(error) {
      if (APPLICATION_ENV === 'development') {
        console.dir(error);
        console.dir("No sell order Next");
      }
      // alert("in preparation.");
      console.dir("Final in preparation.");
      return;
    }

    // this.price = Order.currentPrice;
    // const pr: string = Order.currentPrice.toString();
    // const pri = Order.currentPrice.div(new BigNumber(10).pow(18));
    // this.price = pri.toString();
    // this.setState({price: pri.toString()});
  }

  // オーダーを確認してから購入 fulfill リクエスト
  getOrder = () => {
    if (!this.state.buyAccount) {
      alert("Prease Connet Meatamask");
      return;
    }
    if (this.state.bnum > this.buyable) {
      // 購入できる枚数の残り制限
      // window.location.reload();
      // return;
    }
    // console.dir("getOrder1");
    if (this.state.creatingOrder === true) {
      // 購入実行中
      return;
    }

    console.dir("now sale range " + this.baseOptionId);

    // 処理中 フラグ
    this.setState({creatingOrder: true});

    this.setState({message: '<i class="fa fa-spinner fa-spin fa-lg"></i><b>The YOME will soon be yours.<br />Please Wait.</b>'});
    // this.setState({message: 'Tap The Card to Get YOME'});

    // console.dir("getOrder2");
    this.getOrderAsync(this.state.buyAccount);
    // console.dir("getOrder3");
  }

  async getOrderAsync(buyAddress: string){
    // console.dir("getOrder");
    // console.dir(buyAddress);
    // console.dir('buy num ' + this.state.bnum); // 購入数
    const bnum: number = this.baseOptionId + this.state.bnum -1;
    console.dir('buy num ' + bnum); // 購入数
    console.dir(this.contractAddress); // 購入対象
    console.dir(this.seaport); // 購入対象

    let Order: any = {};
    try {
    Order = await this.seaport.api.getOrder({
      asset_contract_address: this.contractAddress
      // asset_contract_address: "0x560704fdb4905a3649660dd3a626b8b3d396ea66"
    , token_id: bnum
    , side: OrderSide.Sell
    })
    } catch(error) {
      console.dir(error);
      alert("We are preparing right now.");
      this.setState({creatingOrder: false});
      this.setState({message: '<b>Tap The Card to Get YOME</b>'});
      return;
    } finally {
      // console.dir('finaly 1 ');
      // this.setState({creatingOrder: false});
    }
     //console.dir(Order);

    if (buyAddress === Order.maker) {
      console.log("buy self item");
      alert("Buy self item error.");
      this.setState({creatingOrder: false});
      this.setState({message: '<b>Tap The Card to Get YOME</b>'});
      return;
    }

    // 購入リクエスト
    try {
      const transactionHash = await this.seaport.fulfillOrder({
        apiKey: "e33abfd758b4455792aed82b8f8bc786",
        order: Order
      , accountAddress: buyAddress
      })
      console.dir("transaction get YOME");
      this.setState({message: 'Get YOME!! Check OpenSea<br /><a href="https://opensea.io/collection/crypto-seal" target="_blank"><img src="/images/opensea-logo-full-colored-blue.png" width="160px" /></a><br />'});
      // console.dir(transactionHash);

    // 購入可能数更新
    // const url = "https://crypt-zdf8aj6gk.softhouse-seal.com/getsupabl.php";
    const url = "https://crypto.softhouse-seal.com/api/updatesupabl.php";
    axios.get(url).then(res => {
      if (APPLICATION_ENV === 'development') {
        console.log(res.data);
      }
      this.supply = res.data.supply;
      this.buyable = res.data.buyable;
      this.setState({supply: this.supply});
    });
    // console.dir("-----------fulfilled ---------");

    } catch(error) {
      // console.dir(error);
      console.dir("fulfill error");
      alert('Get YOME Error. Please Check your Wallet Balance.');
    } finally {
      // console.dir('finaly 2 ');
      this.setState({creatingOrder: false});
      // this.setState({message: 'Tap The Card to Get YOME'});
    }
  }

  incBar() {
    const p = this.state.supply / 5020 * 100;
    const { percent } = this.state;
    const newPercent = percent + 1;
    if (newPercent >= p) {
      clearTimeout(this.tm);
      return;
    }
    this.setState({ percent: newPercent });
    this.tm = window.setTimeout(this.incBar, 50);
  }

  handleChange = (e: any) => {
    console.dir(e.target.value);
    this.setState({bnum: e.target.value})
    // console.dir(this.state.bnum);
  }

  incNum = () => {
     //this.setState({showFlag: 'none'});
    let n = this.state.bnum + 1; 
    if (n > 10) {
      n = 10;
    }
    if (n > this.buyable) {
      n = this.buyable;
    }
    this.setState({bnum: n})
    // console.dir(this.state.bnum);
  }
  decNum = () => {
    let n = this.state.bnum - 1; 
    if (n < 1) {
      n = 1;
    }
    this.setState({bnum: n})
  }

  Price = () => {
    console.dir(this.state.bnum);
    return <span>ffff</span>
  }

  connect2 = () => {
// console.dir("ConnecButton2");
// alert("c-------2");
      if (typeof window.ethereum !== 'undefined') {
        console.log('Metamask is installed!');
      } else {
        alert("Please install matamask extenttion. Or Open this page metaMask browser.");
        return;
      }
// console.dir("call getAccount");
      getAccount();
  }

  updateSalse() {
    this.updateSalseAsync(); 
  }
  async updateSalseAsync() {
    // 購入可能数取得
    const url = "https://crypto.softhouse-seal.com/api/getsupabl.php";
    try {
      const res = await axios.get(url);
      if (APPLICATION_ENV === 'development') {
        // console.log(res.data);
      }
      this.supply = res.data.supply;
      this.setState({ supply: res.data.supply });

// console.dir(this.state.supply);
      // this.setState({ percent: p });
      clearTimeout(this.tm); 
      this.setState({ percent: 0 }, () => {
        this.incBar();
      });

    } catch(error) {
      console.dir(error.message);
    };



  }

}
/*
  function ConnectButton() {
// console.dir("ConnecButton1");
    const connectClick = () => {
      if (typeof window.ethereum !== 'undefined') {
        console.log('Metamask is installed!');
      } else {
        alert("Please install matamask extenttion. Or Open this page metaMask browser.");
        return;
      }
// console.dir("call getAccount");
      getAccount();
    }

    return <span id="cButton" className="button" onClick={connectClick}>Connect Wallet</span>
  }
*/  
  async function getAccount() {
    console.dir("getAccount");
    try {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch (error) {
        if (error.code === -32002) {
            // alert("getAccount error. -32002");
            alert('Connenting Metamask. Please Check metamask browser Menu');
        }
        console.dir("getAccount error.");
        console.dir(error);
        // alert("getAccount error.");
    }
    // const account = accounts[0];
    // this.account1 = account;
    window.location.reload();
  }


/*
  function TestButton() {
    const [count, setCount] = useState(0);
    const testClick = () => {
        setCount(count + 1);
    }
    console.dir("----------" + count);
    return <span className="testbutton" onClick={testClick}>Testボタン</span>
  }
*/

export default App;
