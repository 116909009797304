import React, { MouseEvent } from 'react'
import ReactDOM from 'react-dom';
import { textChangeRangeIsUnchanged } from 'typescript';
//import axios from "axios";

const APPLICATION_ENV = process.env.REACT_APP_APPLICATION_ENV!;
//console.log(APPLICATION_ENV);

//const ITEM_CONTRACT_TEST = "0x5ddb47ee5a8c53c6a691de7db99a53e6c63e3748";
//const ITEM_CONTRACT_LIVE = "0x95ae387d8da3c218054772f682b662ef4fca7b8e";
//const ITEM_CONTRACT = APPLICATION_ENV === 'development' ? ITEM_CONTRACT_TEST : ITEM_CONTRACT_LIVE;

class ItemsList extends React.Component <{onselect: any, default_selected: number, disable_selected: number, listData: any[]}, {visible_select_items: string, selected: number}>{
//  public listData: any[] = [];

  constructor(props: {onselect: any, default_selected: number, disable_selected: number, listData: any[]}) {
    super(props);
    this.state = {
      visible_select_items: 'none',
      selected: this.props.default_selected,
    };
//    console.log("ItemList constructor.");
//    if(props.update){
      //this.getMyItems();
//    } 
//    this.listData = this.props.listData;
  }

  async componentDidMount() {
//    console.log("itemlist.componentDidMount");
    //await this.getMyItems();

/*
    await this.getMyItems().then( res => {
      this.listData = res;
      console.log(this.listData);
      ReactDOM.render(<ItemsList onselect={this.props.onselect} default_selected={this.props.default_selected} disable_selected={this.props.disable_selected} />,document.getElementById('items_list'));
    });
*/    
  }

  render(){
//    console.log("itemlist.render");
//    console.log("selected:" + this.state.selected);
//    console.log("props selected:" + this.props.default_selected);
 //   console.log("disabled:" + this.props.disable_selected);

    return(
      <div id="itemslist">
        {this.props.listData.map((item: any) => {
//          console.log("token_id: "+item["token_id"]);
          return this.insertImage(item);
        })}
      </div>
    )
  }

  insertImage(item: any){
    const image_path: string = item["image_preview_url"];
    const token_id: number = item["token_id"];
    const item_selected: string = this.is_selected(token_id) ? "inline-block":"none";
    if(image_path == null){
      return (
        <div className="item" onClick={(e)=> this.select_item(e,token_id,image_path)}>
          <video className="image" src={image_path } >
          </video>
          <div className="selected_item token_id">{token_id}</div>
          <div className="selected_item" style={{ display : item_selected }}></div>
        </div>
      )
    }
    if( image_path.slice(-4) === ".mp4" ){
      return (
        <div className="item" onClick={(e)=> this.select_item(e,token_id,image_path)}>
          <video className="image" src={image_path } >
          </video>
          <div className="selected_item token_id">{token_id}</div>
          <div className="selected_item" style={{ display : item_selected }}></div>
        </div>
      )
    }else{
      return (
        <div className="item" onClick={(e)=> this.select_item(e,token_id,image_path)}>
          <img className="image" src={image_path} alt={String(token_id)}/>
          <div className="selected_item token_id">{token_id}</div>
          <div className="selected_item" style={{ display : item_selected }} ></div>
        </div>
      )
    }

  } 
/*
  async getMyItems() {
    console.log("load items list.");
    this.props.listData = [];
    const limit = 50;
//    const GET_ASSETS_API_TEST = "https://rinkeby-api.opensea.io/api/v1/assets?owner="+window.account+"&asset_contract_address="+ITEM_CONTRACT+"&order_direction=desc&limit="+limit+"&offset=";
    const GET_ASSETS_API_TEST = "http://192.168.11.16/nft/api/getasset.php?owner="+window.account+"&contract="+ITEM_CONTRACT+"&limit="+limit+"&offset=";
    const GET_ASSETS_API_LIVE = "https://api.opensea.io/api/v1/assets?owner="+window.account+"&asset_contract_address="+ITEM_CONTRACT+"&order_direction=desc&limit="+limit+"&offset=";
    const GET_ASSETS = APPLICATION_ENV === 'development' ? GET_ASSETS_API_TEST : GET_ASSETS_API_LIVE;

    let offset : number = 0;

    const axiosInstance = axios.create({
      headers: {'X-API-KEY': 'e33abfd758b4455792aed82b8f8bc786'}
    });
    while(true){
      const res = await axiosInstance.get(GET_ASSETS+offset);
      console.log(res);
      //return res.data.assets;
      if(res.status === 200){
        if(res.data.assets.length == 0){
          return ;
        }
    
        this.listData = this.listData.concat(res.data.assets);
        console.log(this.listData);
        ReactDOM.render(<ItemsList onselect={this.props.onselect} default_selected={this.props.default_selected} disable_selected={this.props.disable_selected} update={false} listData={this.listData}/>,document.getElementById('items_list'));
        offset += limit;
      }
    }
*/
/*
    return fetch("https://api.opensea.io/api/v1/assets?order_direction=desc&offset=0&limit=20&owner=0x20d5d5ca61a34643584fC8aA16098344d4A74D71&asset_contract_address=0x95ae387d8da3c218054772f682b662ef4fca7b8e")
    .then(res => {
      return res.json();
    })
    .then(json => {
      return json.assets;
    });
*/    
//  }

  select_item = (e :MouseEvent,token_id: number, image_path: string ) => {
//    console.log("click select_item");
    e.preventDefault();
/*    
    console.log("selected:"+this.state.selected);
    console.log("state selected:"+this.props.default_selected);
    console.log("state disable:"+this.props.disable_selected);
    console.log("now click:"+token_id);
*/    
    if(this.props.disable_selected === token_id){
      return;
    }

//    if(this.state.selected === token_id){
    if(this.props.default_selected === token_id){
//      console.log("unselect "+this.state.selected);
//      console.log("unselect "+this.props.default_selected);
      this.setState({selected: 0});
      token_id=0;
      image_path="";
    }else{
//      console.log("select "+token_id);
      this.setState({selected: token_id});
    }
    //console.log(propsw);
    ReactDOM.render(<ItemsList onselect={this.props.onselect} default_selected={this.state.selected} disable_selected={this.props.disable_selected} listData={this.props.listData}/>,document.getElementById('items_list'));

    this.props.onselect(token_id, image_path);

  }

  is_selected(token_id: number){
    if(this.props.disable_selected === token_id){
      return true;
    }

//    if(this.state.selected === token_id){
    if(this.props.default_selected === token_id){
      return true;
    }

    return false;
  }

}

export default ItemsList;